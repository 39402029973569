import { FC, useState, useEffect, useRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { InferType, boolean, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IInviteGroup } from '../../types/databaseTypes';
import {
    UserCircleIcon,
    CheckCircleIcon,
    // NoSymbolIcon
    PlusCircleIcon,
    // PencilSquareIcon,
    TrashIcon,
} from '@heroicons/react/20/solid';

const formSchema = object({
    name: string().required(),
    child: boolean(),
    vegan: boolean(),
});

export type ViewInviteFormValues = InferType<typeof formSchema>;

interface ViewInviteGroupProps {
    inviteGroup: IInviteGroup;
    onUpdate: (inviteGroup: IInviteGroup) => void;
}

export const ViewInviteGroup: FC<ViewInviteGroupProps> = ({
    inviteGroup,
    // editable,
    onUpdate,
}) => {
    const [addNewInvite, setAddNewInvite] = useState(false);

    const openNewInvite = () => {
        setAddNewInvite(true);
    };

    const closeNewInvite = () => {
        setAddNewInvite(false);
        reset({ name: '' });
    };

    const attendingResults = (
        responded: boolean,
        ceremony: boolean,
        party: boolean,
        breakfast?: boolean,
    ) => {
        if (!responded) return 'text-gray-300';
        if (inviteGroup.includesBreakfast) {
            if (ceremony && breakfast && party) return 'text-green-300';
            else if (ceremony || breakfast || party) return 'text-yellow-300';
        } else {
            if (ceremony && party) return 'text-green-300';
            else if (ceremony || party) return 'text-yellow-300';
        }
        return 'text-red-300';
    };

    const listBottomRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (listBottomRef.current) {
            listBottomRef.current?.scrollTo({
                behavior: 'smooth',
            });
        }
    }, [addNewInvite]);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<ViewInviteFormValues>({
        resolver: yupResolver(formSchema),
        // mode: "onChange"
    });
    const onSubmit: SubmitHandler<ViewInviteFormValues> = (values) => {
        console.log(values);
        const date = new Date();
        const formattedDate = date.toLocaleDateString('en-GB');
        inviteGroup.invites.push({
            id: Math.random().toString(), // TODO: new unique ID generation
            name: values.name,
            createdAt: formattedDate,
            updatedDate: formattedDate,
            responded: false,
            ceremony: {
                attending: false,
            },
            ...(inviteGroup.includesBreakfast && {
                breakfast: {
                    attending: false,
                    starter: '',
                    main: '',
                    dessert: '',
                    child: values.child,
                    vegan: values.vegan,
                },
            }),
            party: {
                attending: false,
            },
        });
        onUpdate(inviteGroup);
        closeNewInvite();
    };

    return (
        <ul
            role="list"
            className="divide-y divide-gray-100 overflow-y-auto font-text"
        >
            <div className="overflow-y-auto">
                {inviteGroup.invites.map((user) => (
                    <li
                        key={user.id}
                        className="flex justify-between gap-x-6 py-5"
                    >
                        <div className="flex min-w-0 gap-x-4">
                            <CheckCircleIcon
                                className={`h-12 w-12 flex-none rounded-full ${attendingResults(
                                    user.responded,
                                    user.ceremony.attending,
                                    user.party.attending,
                                    user.breakfast?.attending,
                                )}`}
                                aria-hidden="true"
                            />
                            <div className="min-w-0 flex-auto flex flex-col items-start">
                                <p className="text-base font-semibold leading-6 text-gray-900">
                                    {user.name}
                                </p>
                                {!user.responded && (
                                    <p className="mt-1 truncate text-sm leading-5 text-gray-500">
                                        Awaiting Response
                                    </p>
                                )}
                                {user.responded &&
                                    inviteGroup.includesBreakfast &&
                                    user.breakfast?.attending && (
                                        <p className="mt-1 truncate text-sm leading-5 text-gray-500">
                                            {user.breakfast?.starter + ', '}
                                            {user.breakfast?.main + ', '}
                                            {user.breakfast?.dessert}
                                        </p>
                                    )}
                                {user.responded && user.dietaryRequirements && (
                                    <p className="mt-1 truncate text-sm leading-5 text-gray-500 flex-wrap">
                                        {'DR: ' + user.dietaryRequirements}
                                    </p>
                                )}
                            </div>
                        </div>
                    </li>
                ))}
            </div>
            {addNewInvite && (
                <li>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex justify-between flex-col sm:flex-row gap-x-6 py-5"
                    >
                        <div className="flex min-w-0 gap-x-4 items-center">
                            <UserCircleIcon
                                className="h-12 w-12 flex-none rounded-full text-gray-300"
                                aria-hidden="true"
                            />
                            <div className="min-w-0 flex-auto flex flex-col">
                                <input
                                    {...register('name')}
                                    id="invite-group-name"
                                    type="text"
                                    placeholder="Name..."
                                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                                        errors.name?.message
                                            ? 'border-red-500 border-2'
                                            : 'border-gray-300'
                                    }`}
                                />
                                {inviteGroup.includesBreakfast && (
                                    <div className="w-full pt-2 px-4 grid grid-cols-2 text-sm leading-5">
                                        <div className="flex flex-row">
                                            <input
                                                {...register('child')}
                                                id="child-checkbox"
                                                type="checkbox"
                                            />
                                            <label
                                                className="ml-1 text-sm font-normal leading-5 text-gray-900 flex text-left"
                                                htmlFor="child-checkbox"
                                            >
                                                child
                                            </label>
                                        </div>
                                        <div className="flex flex-row">
                                            <input
                                                {...register('vegan')}
                                                id="vegan-checkbox"
                                                type="checkbox"
                                            />
                                            <label
                                                className="ml-1 text-sm font-normal leading-5 text-gray-900 flex text-left"
                                                htmlFor="vegan-checkbox"
                                            >
                                                vegan
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-end">
                            <button
                                type="button"
                                className="rounded-md p-2.5 text-gray-700"
                                onClick={closeNewInvite}
                            >
                                <TrashIcon className="h-6 w-6" />
                            </button>
                            <button
                                type="submit"
                                className="rounded-md p-2.5 text-gray-700"
                            >
                                <CheckCircleIcon className="h-6 w-6" />
                            </button>
                        </div>
                    </form>
                </li>
            )}
            <li className="flex place-content-center pt-4">
                <div ref={listBottomRef}>
                    <button onClick={openNewInvite}>
                        <PlusCircleIcon
                            className="h-12 w-12 flex-none rounded-full text-gray-300"
                            aria-hidden="true"
                        />
                    </button>
                </div>
            </li>
        </ul>
    );
};
