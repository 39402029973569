import { FC } from 'react';
import { NavBar } from '../../components/Navbar/NavBar';
import { TitleBanner } from '../../components/Banners/TitleBanner';
import { TitleBannerImageOption } from '../../types/Enums';

export const RegistryPage: FC = () => {
    return (
        <div className="bg-rose-100 min-h-screen h-full">
            <header className="absolute inset-x-0 top-0 z-50">
                <NavBar />
            </header>

            <TitleBanner
                title="Registry"
                image={TitleBannerImageOption.STROLL}
            />

            <div className="relative isolate px-6 py-4 lg:px-8 mt-[88px] lg:mt-[112px]">
                <div className="text-center mx-auto max-w-2xl pb-32 sm:pb-36 lg:pb-42">
                    <div className="bg-white bg-opacity-50 p-4 border border-zinc-800">
                        <p className="text-xl sm:text-2xl leading-6 text-gray-600 font-subtitle">
                            Your presence at our wedding is the greatest gift
                            you could give us.
                        </p>
                        <p className="mt-6 text-xl sm:text-2xl leading-6 text-gray-600 font-subtitle">
                            No gifts are expected, but if you'd like, a
                            contribution towards our honeymoon fund would be
                            truly appreciated. We do not mind if it is by bank
                            transfer, cash, or paypal. A paypal link is provided below, please ask us for other payment method
                            details.
                        </p>
                        <p className="mt-6 text-xl sm:text-2xl leading-6 text-gray-600 font-subtitle">
                            <a href="https://www.paypal.me/mcurley4" >
                                https://www.paypal.me/mcurley4
                            </a>
                        </p>
                        
                        <p className="mt-6 text-xl sm:text-2xl leading-6 text-gray-600 font-subtitle">
                            We look forward to celebrating with you!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
